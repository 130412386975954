import React from 'react';
import { bool, func, string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import { propTypes } from '../../util/types';
import { LicenceDetailsForm } from '../../forms';
import { NamedLink } from '..';

import css from './UpdateLicenceModal.module.css';

const UpdateLicenceModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    currentUser,
    saveProfileError,
    onChange,
    onSubmit,
    ready,
    inProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const currentDriversLicenceExpiryDate = currentUser
    ? currentUser.attributes?.profile?.protectedData?.driversLicenceExpiryDate
    : null;

  const maybeDriversLicenceExpiryDate =
    typeof currentDriversLicenceExpiryDate === 'string'
      ? {
          day: null,
          month: null,
          year: null,
        }
      : currentDriversLicenceExpiryDate;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>Update your licence details</p>
      <p className={css.modalMessage}>
        Please update your drivers licence expiry date to proceed with your booking request.
      </p>
      <p>
        If any of your other licence details are incorrect, you can edit them on your{' '}
        <NamedLink name="ContactDetailsPage">Account page.</NamedLink>.
      </p>
      <LicenceDetailsForm
        className={css.form}
        initialValues={{ driversLicenceExpiryDate: maybeDriversLicenceExpiryDate }}
        saveProfileError={saveProfileError}
        currentUser={currentUser}
        onSubmit={onSubmit}
        onChange={onChange}
        inProgress={inProgress}
        ready={ready}
        formId="LicenceDetailsForm"
      />
    </Modal>
  );
};

UpdateLicenceModal.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  saveProfileError: null,
  inProgress: false,
};

UpdateLicenceModal.propTypes = {
  className: string,
  rootClassName: string,
  currentUser: propTypes.currentUser,
  isOpen: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  ready: bool.isRequired,
  saveProfileError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(UpdateLicenceModal);
